import React from "react";

import clsx from "clsx";
import { Space } from "antd";

import useGetDeliveryDate from "../../../hooks/useGetDeliveryDate";
import { ProductInfoPopUp, ProductTitle } from "../../product";
import { productAttributes } from "../../../api/productAttributes";

interface WeekPlannerProductInformationCellSimpleProps {
  itemRowData: any;
  weekDaysData: any;
  className?: string;
  getContainer?: () => HTMLElement;
}
const WeekPlannerSimpleProductInformationCell: React.FC<
  WeekPlannerProductInformationCellSimpleProps
> = (props: WeekPlannerProductInformationCellSimpleProps) => {
  const { itemRowData, weekDaysData, className, getContainer } = props;

  const { icons, productItem } = itemRowData;
  const {
    brand,
    name,
    sku,
    attributes = {},
    availabilities = {},
  } = productItem || {};

  const { [productAttributes.caliber]: caliber } = attributes;

  const [deliveryDate] = useGetDeliveryDate();

  let isUnavailable = false;
  let explanation = null;

  if (availabilities && availabilities.length > 0 && weekDaysData) {
    isUnavailable = availabilities.every((availabilityEntry: any) => {
      const isAvailable =
        availabilityEntry?.isAvailable || availabilityEntry?.isNeverOutOfStock;
      const currentDayData = weekDaysData.find(
        (weekdayData: any) =>
          weekdayData.apiDate === availabilityEntry.deliveryDate
      );

      return !isAvailable && !currentDayData?.isPastDate;
    });

    // set the first found reason, string typecast is ignored, if value is undefined
    explanation =
      (availabilities.find(
        (availabilityEntry: any) => availabilityEntry.explanation
      )?.explanation as string) || null;
  }

  if (!productItem) {
    return <></>;
  }

  return (
    <div
      className={clsx(
        "weekplanner-product-information-cell--simple",
        className
      )}
    >
      <Space className="justify-between width-full" align="start">
        <ProductTitle
          deliveryDate={deliveryDate}
          sku={sku}
          showAsUnavailable={isUnavailable}
          attributes={attributes}
          availabilities={availabilities}
          title={
            <div>
              <strong>{name}</strong>, {brand}&nbsp;
              <span className="text-disabled">{caliber}</span>
            </div>
          }
          unavailableHint={explanation}
          getContainer={getContainer}
        />
        <ProductInfoPopUp
          icons={icons}
          attributes={attributes}
          sku={sku}
          className="infoIcon"
          getContainer={getContainer}
        />
      </Space>
    </div>
  );
};

export default WeekPlannerSimpleProductInformationCell;
