import React, { useState } from "react";
import { useSelector } from "react-redux";
import { CancelTokenSource } from "axios";
import clsx from "clsx";
import { Spin } from "antd";
import { NavHashLink as NavLink } from "react-router-hash-link";
import { CartMessageEntry } from "../../../types/cartMessageEntry";
import { ProductAttributes, ProductTitle } from "../../product";
import ProductAlternativesModal from "../../product/ProductModal/ProductAlternativesModal";
import ProductBasketPreorder from "../../product/ProductBasket/ProductBasketPreorder";
import { ReactComponent as CloseIcon } from "../../../static/svg/close.svg";
import deleteCartMessageAlternative from "../../../state/actions/deleteCartMessageAlternative";
import elementScrollToPosition from "../../../utils/elementScrollToPosition";

interface Props {
  cartMessage: CartMessageEntry;
  hasAlternativesButton: boolean;
  hasPreorderBasket: boolean;
  cancelTokenSource: CancelTokenSource;
}

/**
 * visual representation of cartMessages if an item was deleted by the system
 * @param {CartMessageEntry} cartMessage
 * @param {boolean} hasAlternativesButton
 * @param {boolean} hasPreorderBasket
 * @param {CancelTokenSource} cancelTokenSource
 * @constructor
 */
const DeletedOrChangedCartItemEntry = function DeletedOrChangedCartItemEntry({
  cartMessage,
  hasAlternativesButton,
  hasPreorderBasket,
  cancelTokenSource,
}: Props) {
  const { deliveryDate } = useSelector(
    (state: any) => state.currentCartMetaData
  );
  const [isLoading, setIsLoading] = useState(false);

  const {
    id: messageId,
    item: { attributes, name, nextAvailability, sku },
    isDeleted,
    quantity: itemsInCart,
    quantityAdjusted: newAmountInStock,
  } = cartMessage;
  let nextAvailabilityDate;
  let quantity;

  const amountHasChanged = !isDeleted;

  // could be null
  if (nextAvailability) {
    ({ deliveryDate: nextAvailabilityDate, quantity } = nextAvailability);
  }

  // fake product data object for existing component
  const fakedProductDataObject = {
    attributes,
    name,
    sku,
    availabilities: [
      {
        availability: true,
        deliveryDate,
        explanation: "",
        nextAvailability: nextAvailabilityDate,
        quantity,
        showAvailability: true,
      },
    ],
    itemsInCart,
  };

  /**
   * function to delete a cart message entry
   */
  const deleteMessageEntry = () => {
    setIsLoading(true);

    deleteCartMessageAlternative({
      deliveryDate,
      messageId,
      sku,
      cancelTokenSource,
    });
  };

  /**
   * function to customize the scroll offset behavior of the hash link
   * @param {HTMLElement} element
   */
  const scrollWithOffset = (element: HTMLElement) => {
    const headerHeight = document.getElementById("headerLayout").clientHeight;
    const navHeight = document.getElementById(
      "shopCategoryNavigation"
    ).clientHeight;
    const yCoordinate =
      element.getBoundingClientRect().top + window.pageYOffset;
    // + 20 to save some space at the top of the item, which ensures a better visibility
    const yOffset = headerHeight + navHeight + 20;
    elementScrollToPosition({ top: yCoordinate - yOffset });
  };

  return (
    <Spin
      size="default"
      spinning={isLoading}
      wrapperClassName="cartDeletedItemAlternativeWrapper"
    >
      <div
        className={clsx(
          "deletedOrChangedCartItemEntry",
          {
            hasPreorderBasket,
            hasAlternativesButton,
          },
          amountHasChanged && "changedItemsContext"
        )}
      >
        <ProductTitle
          deliveryDate={deliveryDate}
          sku={sku}
          title={name}
          openProductInNewTab={!isDeleted}
          showAsUnavailable={!!isDeleted}
        />
        {!amountHasChanged && (
          <ProductAttributes
            attributes={attributes}
            deliveryDate={deliveryDate}
            sku={sku}
          />
        )}

        {hasPreorderBasket && nextAvailabilityDate && (
          <ProductBasketPreorder
            deliveryDate={deliveryDate}
            productData={fakedProductDataObject}
            showVolumePrices={false}
            preSelectedAmount={itemsInCart}
          />
        )}

        {amountHasChanged && (
          <div className="changedItemsInfo">
            <span className="mb-xs mr-sm">{`Noch ${newAmountInStock} anstatt ${itemsInCart} im Bestand`}</span>
            <NavLink
              className="directToProductLink"
              to={`#cartItem-${sku}`}
              scroll={scrollWithOffset}
            >
              Artikel im Warenkorb anzeigen
            </NavLink>
          </div>
        )}

        {!amountHasChanged && (
          <ProductAlternativesModal
            sku={sku}
            deliveryDate={deliveryDate}
            itemsInCart={itemsInCart}
          />
        )}

        <button
          type="button"
          className="button buttonText dismissMessageButton"
          onClick={deleteMessageEntry}
        >
          <CloseIcon className="icon iconClose" />
        </button>
      </div>
    </Spin>
  );
};

export default DeletedOrChangedCartItemEntry;
