import React, { useMemo, useRef } from "react";

import clsx from "clsx";
import { useSelector } from "react-redux";

import { Entry } from "contentful";
import aggregateCategoriesArray from "../../../utils/aggregateCategoriesArray";
import findNestedKeyInObject from "../../../utils/findNestedKeyInObject";
import useGetWebshopCategoryVideos from "../../../hooks/useGetWebshopCategoryVideos";
import { WebshopCategoryVideo } from "../../atoms";
import { ContentfulWebshopCategoryVideoEntry } from "../../../types/contentfulWebshopCategoryVideoEntry";
import { RootState } from "../../../types/rootState";

interface WebshopCategoryVideosProps {
  categoryKey: string;
  limit?: number;
  className?: string;
}

const WebshopCategoryVideos: React.FC<WebshopCategoryVideosProps> = (
  props: WebshopCategoryVideosProps
) => {
  const { categoryKey, limit = 1, className } = props;

  const { categories = [] } = useSelector(
    (state: any) => state?.categoryNavigation || {}
  );
  const { stockName } = useSelector((state: RootState) => state?.userData);

  const categoriesCopyRef = useRef<any>(categories);

  const categoryIds: string = useMemo(() => {
    const category = findNestedKeyInObject(
      categoriesCopyRef?.current?.children || [],
      categoryKey,
      "categoryKey",
      "children"
    );

    if (!category) {
      return "";
    }

    const aggregatedCategories = aggregateCategoriesArray(
      category,
      categoriesCopyRef?.current?.children || [],
      []
    );

    if (!aggregatedCategories?.length) {
      return "";
    }

    return aggregatedCategories?.map((cat: any) => cat?.categoryKey)?.join(",");
  }, [categoryKey]);

  const { entries: webshopCategoryVideos, isLoading } =
    useGetWebshopCategoryVideos({
      categoryIds,
      stockName,
      limit,
    });

  if (isLoading || !webshopCategoryVideos?.length) {
    return null;
  }

  return (
    <div className={clsx("webshop-category-videos", className)}>
      {webshopCategoryVideos.map(
        (webshopCategoryVideo: Entry<ContentfulWebshopCategoryVideoEntry>) => (
          <WebshopCategoryVideo
            webshopCategoryVideo={webshopCategoryVideo}
            key={webshopCategoryVideo.sys.id}
          />
        )
      )}
    </div>
  );
};

export default React.memo(WebshopCategoryVideos);
