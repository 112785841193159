import React, { useContext } from "react";
import { Tooltip, Typography } from "antd";
import clsx from "clsx";
import { Link } from "react-router-dom";
import moment from "moment";
import { useDispatch } from "react-redux";
import { ReactComponent as UnavailableIcon } from "../../static/svg/shopping-cart-unavailable.svg";
import { ReactComponent as PreOrderIcon } from "../../static/svg/twoToneIcons/preorder.svg";
import { fullDateFormat } from "../../utils/dateFormats";
import useStockForAttribute from "../../hooks/useStockForAttribute";
import { ProductData } from "../../types/productData";
import getDeliveryDateBasedAttributes from "./getDeliveryDateBasedAttributes";
import useGetProductDetailLink from "../../hooks/useGetProductDetailLink";
import ScannerProductEditModalContext from "../../contexts/ScannerProductEditModalContext";

interface Props {
  deliveryDate: ProductData["deliveryDate"];
  sku: ProductData["sku"];
  title: string | React.ReactNode;
  attributes?: ProductData["attributes"];
  availabilities?: ProductData["availabilities"];
  isDetail?: boolean;
  openProductInNewTab?: boolean;
  showAsUnavailable?: boolean;
  size?: "normal" | "small";
  titleClassName?: string;
  unavailableHint?: string;
  getContainer?: () => HTMLElement;
}
type NumTypes = 1 | 2 | 3 | 4 | 5;

const { Title } = Typography;

/**
 * Render formatted pre order note
 * Take comma separated text and format it as list items
 * @param preOrderNote
 * @param separator
 * @return {null|JSX.Element|JSX.Element[]}
 */
const renderFormattedPreOrderNote = (
  sku: string,
  preOrderNote: string,
  separator = ","
): null | JSX.Element | JSX.Element[] => {
  if (!preOrderNote.length) {
    return null;
  }

  // Remove seconds from time output (e.g. "17:15:00 Uhr" => "17:15 Uhr")
  const preOrderNoteFormatted = preOrderNote.replaceAll(
    /(([0-9]{2}:[0-9]{2}):[0-9]{2})/g,
    "$2"
  );

  if (!preOrderNoteFormatted.includes(separator)) {
    return <li key={`${sku}_1`}>{preOrderNoteFormatted}</li>;
  }

  return preOrderNoteFormatted
    .split(separator)
    .map((line: string, i: number) => <li key={`${sku}_${i}`}>{line}</li>);
};

/**
 * component to show the title of a product
 * @param sku {ProductData["sku"]}
 * @param title {string}
 * @param attributes {ProductData["attributes"]}
 * @param availabilities {ProductData["availabilities"]}
 * @param deliveryDate {ProductData["deliveryDate"]}
 * @param isDetail {boolean}
 * @param openProductInNewTab {boolean}
 * @param titleClassName {boolean}
 * @param showAsUnavailable {"normal" | "small"}
 * @param size {string}
 * @param unavailableHint {string}
 * @constructor
 */
const ProductTitle: React.FC<Props> = function ProductTitle({
  sku,
  title,
  attributes = {},
  availabilities,
  deliveryDate,
  isDetail = false,
  openProductInNewTab = false,
  titleClassName = "",
  showAsUnavailable = null,
  size = "normal",
  unavailableHint = "",
  getContainer,
}: Props) {
  const isInScannerProductEditModalContext = useContext(
    ScannerProductEditModalContext
  );
  const dispatch = useDispatch();
  const getProductDetailLink = useGetProductDetailLink();
  const productDetailLink = getProductDetailLink(sku);

  const lev: NumTypes = isDetail ? 1 : 3;

  const {
    explanation: explanationByData,
    isUnavailable: isUnavailableByData,
    nextAvailability,
  } = getDeliveryDateBasedAttributes({
    deliveryDate,
    availabilities,
  });

  // check if prop is used to overwrite, otherwise fallback to productData value
  const isUnavailable =
    typeof showAsUnavailable === "boolean"
      ? showAsUnavailable
      : isUnavailableByData;
  const explanation = unavailableHint || explanationByData;

  let preOrderTooltipText = <span>Vorbestellartikel</span>;
  let specialPreOrderTooltipText = preOrderTooltipText;

  const isPreOrderArticle = useStockForAttribute(
    attributes,
    "isPreOrderArticle"
  );

  const preOrderDeliveryDate = useStockForAttribute(
    attributes,
    "preOrderDeliveryDate"
  );

  const preOrderOrderDate = useStockForAttribute(
    attributes,
    "preOrderOrderDate"
  );
  const preOrderNote = useStockForAttribute(attributes, "preOrderNote");

  const isSpecialPreOrderArticle = isPreOrderArticle === "sonder";

  if (isSpecialPreOrderArticle && nextAvailability) {
    specialPreOrderTooltipText = (
      <ul className="pre-order-note-list">
        {!!explanation && <li>{explanation}</li>}
        <li>Lieferbar ab: {moment(nextAvailability).format(fullDateFormat)}</li>
      </ul>
    );
  }

  if (preOrderDeliveryDate || preOrderOrderDate || !!preOrderNote) {
    // if there are 2 deliveryDates separated with a ',' then we have a date range, else not ;O)
    const deliveryDates = preOrderDeliveryDate.split(",");

    preOrderTooltipText = (
      <ul className="pre-order-note-list">
        {preOrderOrderDate && (
          <li key={`${sku}_pre_order_order_date`}>
            Zu bestellen bis:&nbsp;
            {moment(preOrderOrderDate).format(fullDateFormat)}
          </li>
        )}
        {preOrderDeliveryDate && (
          <li key={`${sku}_pre_order_delivery_date`}>
            Lieferbar&nbsp;{deliveryDates?.length > 1 ? "zwischen" : "am"}
            :&nbsp;
            {deliveryDates?.map((deliveryDateInfo: string, index: number) => {
              // add a '-' before the to-date
              return `${index === 1 ? " - " : ""}${moment(
                deliveryDateInfo
              ).format(fullDateFormat)}`;
            })}
          </li>
        )}
        {!!preOrderNote && renderFormattedPreOrderNote(sku, preOrderNote)}
      </ul>
    );
  }

  // "unavailableHint" could be an empty string. This solves both situations.
  const isAvailabilityExplained =
    explanation ||
    "Das Produkt ist zum gewünschten Liefertermin nicht verfügbar";

  return (
    <Title
      level={lev}
      className={clsx("productTitle", titleClassName, {
        "like-h1": isDetail,
        "like-h4": size === "normal" && !isDetail,
        "text-large": size === "small" && !isDetail,
        isUnavailable,
      })}
    >
      {isUnavailable && (!isSpecialPreOrderArticle || !nextAvailability) && (
        <span className="unavailableIcon">
          <Tooltip
            title={isAvailabilityExplained}
            placement="bottomLeft"
            getTooltipContainer={getContainer}
            trigger={["hover", "click"]}
            destroyTooltipOnHide
          >
            <UnavailableIcon />
          </Tooltip>
        </span>
      )}

      {isUnavailable && isSpecialPreOrderArticle && nextAvailability && (
        <span className="preOrderIcon">
          <Tooltip
            title={specialPreOrderTooltipText}
            placement="bottomLeft"
            getTooltipContainer={getContainer}
            trigger={["hover", "click"]}
            destroyTooltipOnHide
          >
            <PreOrderIcon
              className={clsx("icon iconTwoTone iconTwoTone--customPreorder")}
            />
          </Tooltip>
        </span>
      )}

      {isPreOrderArticle?.length > 0 && !isSpecialPreOrderArticle && (
        <span className="preOrderIcon">
          <Tooltip
            title={preOrderTooltipText}
            placement="bottomLeft"
            getTooltipContainer={getContainer}
            trigger={["hover", "click"]}
            destroyTooltipOnHide
          >
            <PreOrderIcon
              className={clsx(
                "icon iconTwoTone",
                isSpecialPreOrderArticle && "iconTwoTone--customPreorder"
              )}
            />
          </Tooltip>
        </span>
      )}

      {!isDetail && deliveryDate && !isInScannerProductEditModalContext ? (
        <Link
          to={productDetailLink}
          className="productTitleLink"
          target={openProductInNewTab ? "_blank" : null}
          onClick={() => {
            dispatch({
              type: "page/set-scroll",
              payload: {
                x: window.pageXOffset,
                y: window.pageYOffset,
              },
            });
          }}
        >
          <span className="productTitleInner">{title}</span>
        </Link>
      ) : (
        <span className="productTitleInner">{title}</span>
      )}
    </Title>
  );
};

export default ProductTitle;
