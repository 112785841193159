import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { CancelTokenSource } from "axios";
import moment from "moment";
import getAlternativeProducts from "../../../api/products/getAlternativeProducts";
import { ProductData } from "../../../types/productData";
import requestCatchHandler from "../../../api/requestCatchHandler";
import getCancelTokenSource from "../../../api/getCancelTokenSource";
import ProductViewer from "./ProductViewer";
import { ModalButton } from "../../../types/button";
import { fullDateFormat } from "../../../utils/dateFormats";

interface Props {
  deliveryDate: ProductData["deliveryDate"];
  sku: ProductData["sku"];
  buttonStyle?: ModalButton;
  itemsInCart?: number;
  getContainer?: () => HTMLElement;
}
/**
 * button and modal component for displaying product alternatives
 * @param {ProductData["deliveryDate"]} deliveryDate
 * @param {ProductData["sku"]} sku
 * @param {ModalButton} buttonStyle
 * @param {number} itemsInCart
 * @param getContainer
 * @constructor
 */
const ProductAlternativesModal = function ProductAlternativesModal({
  deliveryDate,
  sku,
  buttonStyle = "primary",
  itemsInCart = 1,
  getContainer,
}: Props) {
  const dispatch = useDispatch();
  const [alternativeProducts, setAlternativeProducts] = useState<ProductData[]>(
    []
  );

  const getProducts = useCallback(
    (cancelTokenSource: CancelTokenSource) => {
      getAlternativeProducts(sku, deliveryDate, cancelTokenSource)
        .then((productsResponse) => {
          setAlternativeProducts(productsResponse);

          // push info to redux for current cart to operate with data in cart route
          if (productsResponse?.length) {
            dispatch({
              type: "cart/set-alternatives",
              payload: { [sku]: productsResponse },
            });
          } else {
            dispatch({
              type: "cart/delete-alternative",
              payload: sku,
            });
          }
        })
        .catch(requestCatchHandler);
    },
    [dispatch, sku, deliveryDate]
  );

  useEffect(() => {
    // create token for cleanup cancel
    const productsCancelTokenSource = getCancelTokenSource();

    getProducts(productsCancelTokenSource);

    return () => {
      productsCancelTokenSource.cancel();
    };
  }, [getProducts]);

  return alternativeProducts.length > 0 ? (
    <ProductViewer
      buttonClass="productAlternativesButton"
      buttonStyle={buttonStyle}
      buttonTitle="Alternativen finden"
      deliveryDate={deliveryDate}
      products={alternativeProducts}
      defaultQuantity={itemsInCart}
      title={`${
        alternativeProducts.length === 1
          ? "Alternatives Produkt"
          : "Alternative Produkte"
      } für ${moment(deliveryDate).format(fullDateFormat)}`}
      getContainer={getContainer}
    />
  ) : null;
};

export default ProductAlternativesModal;
