import React from "react";

import clsx from "clsx";
import {
  ProductAttributes,
  ProductImage,
  ProductInsignias,
  ProductTitle,
  ProductInfoPopUp,
  ProductPackageInfo,
} from "../../product/index";
import { productAttributes } from "../../../api/productAttributes";
import useStockForAttribute from "../../../hooks/useStockForAttribute";
import ProductInfoBadge from "../../product/ProductInfoBadge";
import { ProductData } from "../../../types/productData";
import ProductAddToPriceTagListButton from "../../product/ProductAddToPriceTagListButton";
import useGetDeliveryDate from "../../../hooks/useGetDeliveryDate";
import ProductAddToFavouritesListButton from "../../product/ProductAddToFavouritesListButton";

interface Props {
  icons: any[];
  img: string;
  productData: ProductData;
  weekDaysData: any[];
  getContainer?: () => HTMLElement;
}

const WeekPlannerProductInformationCell: React.FC<Props> =
  function WeekPlannerProductInformationCell({
    img,
    icons,
    productData,
    weekDaysData,
    getContainer,
  }: Props) {
    const [deliveryDate] = useGetDeliveryDate();
    const { attributes, name, sku, availabilities } = productData;

    const {
      [productAttributes.unitQuantity]: productUnitQuantity,
      [productAttributes.package]: productUnitMeasurement,
      [productAttributes.bioIcon]: bioladenLogo,
      [productAttributes.bioFairIcon]: bioFairLogo,
      [productAttributes.isNewArticle]: isNewArticle,
      [productAttributes.deprecatedSku]: deprecatedSku,
      [productAttributes.qualityText]: qualityText,
      [productAttributes.articleStb]: articleStb,
      [productAttributes.articlePoster]: articlePoster,
    } = attributes;

    let isUnavailable = false;
    let explanation = null;
    let nextAvailability = false;

    /*
     * get availability state for product info visual
     * product must not be available for every visible date
     * and date must not be in the past
     */
    if (availabilities && availabilities.length > 0 && weekDaysData) {
      isUnavailable = availabilities.every((availabilityEntry: any) => {
        const isAvailable =
          availabilityEntry?.isAvailable ||
          availabilityEntry?.isNeverOutOfStock;
        const currentDayData = weekDaysData.find(
          (weekdayData: any) =>
            weekdayData.apiDate === availabilityEntry.deliveryDate
        );

        return !isAvailable && !currentDayData?.isPastDate;
      });

      nextAvailability =
        !!availabilities.find(
          (availabilityEntry: any) => !!availabilityEntry?.nextAvailability
        )?.nextAvailability || false;

      // set the first found reason, string typecast is ignored, if value is undefined
      explanation =
        (availabilities.find(
          (availabilityEntry: any) => availabilityEntry.explanation
        )?.explanation as string) || null;
    }

    const advertisement = useStockForAttribute(attributes, "advert");

    return (
      <div
        className={clsx("weekplannerProductInformationCell", {
          isUnavailable,
        })}
      >
        <ProductImage
          sku={sku}
          imageSet={img}
          alt={name}
          className={clsx(
            { isUnavailable: isUnavailable && !nextAvailability },
            "weekplannerProductImage"
          )}
          imageType="weekplanner"
        />

        <ProductInfoBadge
          view="weekplanner"
          advertisement={advertisement}
          isNewArticle={isNewArticle}
          deprecatedSku={deprecatedSku}
          getContainer={getContainer}
          isArticlePosterAvailable={articlePoster}
        />

        <div className="weekplannerProductTitleWrapper">
          <ProductTitle
            deliveryDate={deliveryDate}
            attributes={attributes}
            sku={sku}
            showAsUnavailable={isUnavailable}
            title={name}
            titleClassName="color-primary weekplannerProductTitle"
            unavailableHint={explanation}
            getContainer={getContainer}
          />

          <ProductPackageInfo
            productUnitQuantity={productUnitQuantity}
            productUnitMeasurement={productUnitMeasurement}
            sku={sku}
            className="weekplannerPackagingInfo"
            showProductUVP
          />
        </div>

        <ProductAttributes
          attributes={attributes}
          className="weekplannerProductAttributes"
          deliveryDate={deliveryDate}
          sku={sku}
        />

        <div className="productInfoIcons weekplannerProductInfoIcons">
          <ProductAddToPriceTagListButton sku={sku} />

          <ProductAddToFavouritesListButton
            sku={sku}
            getContainer={getContainer}
          />

          <ProductInfoPopUp
            icons={icons}
            attributes={attributes}
            sku={sku}
            className="infoIcon"
            getContainer={getContainer}
          />
        </div>

        <ProductInsignias
          icons={icons}
          bioladenLogo={bioladenLogo}
          bioladenFairLogo={bioFairLogo}
          qualityText={qualityText}
          steinofenbaeckerLogo={articleStb}
        />
      </div>
    );
  };

export default WeekPlannerProductInformationCell;
