import React from "react";

import clsx from "clsx";

import useStockForAttribute from "../../../hooks/useStockForAttribute";
import ProductInfoBadge from "../../product/ProductInfoBadge";
import { productAttributes } from "../../../api/productAttributes";

interface WeekPlannerSkuCellProps {
  itemRowData: any;
  className?: string;
  getContainer?: () => HTMLElement;
}

const WeekPlannerSkuCell: React.FC<WeekPlannerSkuCellProps> = (
  props: WeekPlannerSkuCellProps
) => {
  const { itemRowData, className, getContainer } = props;
  const { productItem } = itemRowData || {};
  const { attributes = {} } = productItem || {};

  const {
    [productAttributes.isNewArticle]: isNewArticle,
    [productAttributes.articlePoster]: articlePoster,
  } = attributes;

  const advertisement = useStockForAttribute(attributes, "advert");

  if (!productItem) {
    return itemRowData;
  }

  return (
    <div className={clsx("weekplanner-sku-cell", className)}>
      {itemRowData?.productItem?.sku}
      <div className="flex ant-space-align-baseline justify-center">
        <ProductInfoBadge
          view="weekplanner"
          advertisement={advertisement}
          isNewArticle={isNewArticle}
          getContainer={getContainer}
          isArticlePosterAvailable={articlePoster}
        />
      </div>
    </div>
  );
};

export default WeekPlannerSkuCell;
