import React, { useMemo } from "react";
import { Col, Layout, Row } from "antd";
import { Link, useLocation } from "react-router-dom";
import clsx from "clsx";
import { useSelector } from "react-redux";
import queryString from "query-string";
import HrDivider from "../divider/Divider";
import { locationSearchQueryParameter, routePathNames } from "../../appConfig";
import createProductViewUrl from "../../utils/createProductViewUrl";
import { ReactComponent as ProductList } from "../../static/svg/productlist.svg";
import { ReactComponent as Weekplaner } from "../../static/svg/weekplaner.svg";
import useGetDeliveryDate from "../../hooks/useGetDeliveryDate";
import { getFilterStatesWithoutDefaults } from "../../api/products/getProductsFilters";

interface Props {
  paginationData: Record<string, unknown>;
  sortBy: string | null;
  sortDirection: string | null;
  categoryKey?: string;
  className?: string;
  children?: React.ReactNode;
  searchQuery?: string;
}

const HeaderButtons: React.FC<Props> = function HeaderButtons({
  paginationData,
  categoryKey,
  className,
  sortBy,
  sortDirection,
  children,
  searchQuery,
}: Props) {
  const { pathname, search } = useLocation();
  const [deliveryDate] = useGetDeliveryDate();
  const { weekDates } = useSelector((state: any) => state.weekplannerData);

  const isWeekplanner = pathname.includes(routePathNames.weekPlanner);
  const isProductList = pathname.includes(routePathNames.products);
  const isSearch = pathname.includes(routePathNames.search);

  // determine the current page title
  const pageTitle = useMemo(() => {
    if (isSearch) {
      return "Suche";
    }

    if (isProductList) {
      return "Produkte";
    }

    if (isWeekplanner) {
      return "Wochenplaner";
    }

    return "";
  }, [isProductList, isWeekplanner, isSearch]);

  /**
   * simplify url link creation for button
   */
  const createLinkTo = () => {
    const targetPathname = isWeekplanner
      ? routePathNames.products
      : routePathNames.weekPlanner;

    // retrieve selected filters and add them also to persist filters between views
    const filters = queryString.stringify(
      getFilterStatesWithoutDefaults(false),
      {
        arrayFormat: "separator",
        arrayFormatSeparator: locationSearchQueryParameter.filterConjunction,
      }
    );

    const { search: searchTerm } = queryString.parse(search);

    return createProductViewUrl({
      pathname: targetPathname,
      categoryKey,
      deliveryDate,
      isSearch,
      weekDates,
      filters,
      searchTerm,
      sortBy,
      sortDirection,
    });
  };

  return (
    <Layout
      className={clsx(
        "container-layout container-layout--inner mb-s",
        className
      )}
    >
      <Row
        gutter={{ xs: 4, sm: 4, md: 8, lg: 32 }}
        className="justify-between items-center"
      >
        <Col xs={12} md={6} className="webShopTitle">
          <header className="productHeadingTitle">
            <h1 className="text-uppercase">
              {pageTitle}
              <small className="hidden-sm-down ml-s">
                {isSearch && !!searchQuery && searchQuery.length ? (
                  <>
                    Die Suche nach{" "}
                    <strong className="text-primary">{searchQuery}</strong>{" "}
                    ergab {paginationData.numFound} Treffer
                  </>
                ) : (
                  <span>{` ${paginationData.numFound} Artikel`}</span>
                )}
              </small>
            </h1>
          </header>
        </Col>

        {/* Mobile  weekplannerProductListWrapper */}
        <Col span={12} className="weekplannerProductListWrapper hidden-md-up">
          <div className="mb-s">{children}</div>
          <div className="productHeadingButtons">
            <Link
              to={createLinkTo()}
              className="button buttonSecondary buttonSecondary--inverted buttonWithIcon buttonNavigation height-full justify-between"
            >
              <div className="width-full flex items-center justify-center height-full">
                {isWeekplanner && (
                  <>
                    <div className="width-full flex items-center justify-center items-center">
                      <ProductList className="buttonIcon fillPrimaryColor" />
                      <span className="buttonNavigationText">Produktliste</span>
                    </div>

                    <div className="iconWrapper">
                      <Weekplaner className="buttonIcon" />
                    </div>
                  </>
                )}
                {isProductList && (
                  <>
                    <div className="iconWrapper ">
                      <ProductList className="buttonIcon fillPrimaryColor" />
                    </div>
                    <div className="width-full flex items-center justify-center items-center">
                      <Weekplaner className="calendarIcon" />
                      <span className="buttonNavigationText">Wochenplaner</span>
                    </div>
                  </>
                )}
              </div>
            </Link>
          </div>
        </Col>

        {/* Tablet & Desktop weekplannerProductListWrapper and DatePicker */}
        <Col
          span={6}
          className="desktopTabletDateSelectWrapper hidden-sm-down justify-end"
        >
          {children}
          <div className="productHeadingButtons">
            <Link
              to={createLinkTo()}
              className="button buttonSecondary buttonSecondary--inverted buttonWithIcon buttonNavigation justify-between height-full"
            >
              {isWeekplanner && (
                <>
                  <div className="width-full flex items-center justify-center items-center iconTextButtonContent">
                    <ProductList className="productListIcon fillPrimaryColor" />
                    <span className="buttonNavigationText">Produktliste</span>
                  </div>

                  <div className="iconWrapper calendar flex justify-center items-center">
                    <Weekplaner className="calendarIcon" />
                  </div>
                </>
              )}
              {isProductList && (
                <>
                  <div className="iconWrapper iconWrapperProductList flex items-center justify-center">
                    <ProductList className="productListIcon listIconProductListTablet" />
                  </div>
                  <div className="width-full flex items-center justify-center items-center iconTextButtonContent">
                    <Weekplaner className="calendarIcon" />
                    <span className="buttonNavigationText">Wochenplaner</span>
                  </div>
                </>
              )}
            </Link>
          </div>
        </Col>

        <Col span={12} className="filterPanelToggler">
          <HrDivider
            size={2}
            spacingTop="sm"
            spacingBottom="xs"
            className="hidden-sm-down"
          />
        </Col>
      </Row>
    </Layout>
  );
};

export default HeaderButtons;
