import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Layout, Table, Col, Row, Result, Space } from "antd";
import moment from "moment";
import "moment/locale/de";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import {
  resetFilterStates,
  showClearAllFiltersComponent,
} from "../products/ProductsFilter/filterFunctions";
import {
  WeekPlannerProductCell,
  WeekPlannerProductInformationCell,
  TableTitle,
} from "./components/index";
import { productAttributes } from "../../api/productAttributes";
import { ChildProps } from "../products/ProductsViewWrapper";
import { apiDateFormat, dateFormat } from "../../utils/dateFormats";
import updateWeekplannerDates from "../../state/actions/updateWeekplannerDates";
import ProductAlreadyOrderedBatch from "../product/ProductAlreadyOrderedBatch";
import { ProductData } from "../../types/productData";
import {
  messageData,
  pageTitles,
  pageTitleSuffix,
  routePathNames,
  routeProductCategoryKeys,
} from "../../appConfig";
import useUpdateUrlFragments from "../../hooks/useUpdateUrlFragments";
import compareProductCollectionGroups from "../../utils/compareProductCollectionGroups";
import ButtonBackToTop from "../buttons/ButtonBackToTop";
import EmptyListContent from "../EmptyListContent/EmptyListContent";
import useShouldWeekplannerBeVisible from "../../hooks/useShouldWeekplannerBeVisible";
import { FilterBar, SelectedFilters } from "../molecules";
import { FiltersDesktop } from "../organisms";
import WeekPlannerPriceInformationCell from "./components/WeekPlannerPriceInformationCell";
import TableWeekPagination from "./components/TableWeekPagination";
import useGetSelectedFiltersCount from "../../hooks/useGetSelectedFiltersCount";
import { RootState } from "../../types/rootState";
import getDeliveryDates from "../../utils/getDeliveryDates";
import TrackingHelmet from "../Matomo/TrackingHelmet";
import WeekPlannerVolumePriceCell from "./components/WeekPlannerVolumePriceCell";
import ProductAddToPriceTagListButton from "../product/ProductAddToPriceTagListButton";
import ProductAddToFavouritesListButton from "../product/ProductAddToFavouritesListButton";
import WeekPlannerSimpleProductInformationCell from "./components/WeekPlannerSimpleProductInformationCell";
import WeekPlannerAttributeCell from "./components/WeekPlannerAttributeCell";
import WeekPlannerSkuCell from "./components/WeekPlannerSkuCell";

/**
 *
 * @param {boolean} showFilters
 * @param {ProductData[]} productData
 * @param {boolean} loading
 * @constructor
 */
const WeekPlanner: React.FunctionComponent<ChildProps> = function WeekPlanner({
  showFilters,
  setShowFilters,
  productData,
  isLoading,
  fullscreenRef,
  pagination,
}: ChildProps) {
  const navigate = useNavigate();

  const { selectedFiltersCount } = useGetSelectedFiltersCount();
  const { pathname, search } = useLocation();
  const setUpdateUrlFragments = useUpdateUrlFragments();
  const weekplannerIsVisible = useShouldWeekplannerBeVisible();

  const { deliveryDates: customerDeliveryDates } = useSelector(
    (state: RootState) => state?.userData || {}
  );
  const { deliveryDates: businessUnitDeliveryDates } = useSelector(
    (state: RootState) => state?.userData?.businessUnit || {}
  );
  const { weekplannerUseSimpleMode: useSimpleMode = false } = useSelector(
    (state: RootState) => state?.userData || {}
  );

  const deliveryDates = useMemo(
    () => getDeliveryDates(customerDeliveryDates, businessUnitDeliveryDates),
    [customerDeliveryDates, businessUnitDeliveryDates]
  );

  const [hasRequestedFullScreen, setHasRequestedFullScreen] =
    useState<boolean>(false);
  const [isInFullscreenMode, setIsInFullscreenMode] = useState<boolean>(false);

  // This state handles which columns are shown
  const daysToShow: any = useMemo(() => {
    return {
      Montag: deliveryDates.includes("1"),
      Dienstag: deliveryDates.includes("2"),
      Mittwoch: deliveryDates.includes("3"),
      Donnerstag: deliveryDates.includes("4"),
      Freitag: deliveryDates.includes("5"),
      Samstag: deliveryDates.includes("6"),
    };
  }, [deliveryDates]);

  const showClearAllFilterButton = showClearAllFiltersComponent();

  const locale = useMemo(
    () => ({
      emptyText: isLoading ? (
        <EmptyListContent description="Produkte werden geladen..." />
      ) : (
        <Result
          title={messageData.warning.noProductResults.content}
          extra={
            showClearAllFilterButton && (
              <div className="clearAllFiltersWeekPlanner">
                Filter{" "}
                <button
                  type="button"
                  className="buttonText"
                  onClick={() => resetFilterStates(navigate)}
                >
                  <span className="text-underline">zurücksetzen</span>
                </button>
              </div>
            )
          }
        />
      ),
    }),
    [showClearAllFilterButton, navigate, isLoading]
  );

  const alreadyOrderedItemsForDate = useSelector(
    (state: any) => state.alreadyOrdered?.orderItemIndex
  );

  const { weekDates, weekCarts, minWeek, maxWeek } = useSelector(
    (state: any) => state.weekplannerData
  );

  const [isDragging, setIsDragging] = useState(false);
  const [cursorPosition, setCursorPosition] = useState({
    left: 0,
    top: 0,
    x: 0,
    y: 0,
  });

  const tableWrapperRef = useRef(null);
  const tableRef = useRef(null);

  /**
   * ugly workaround to create a Ref to the ant design table
   * which unfortunately does not support React.forwardRef()... *&!/°=
   */
  if (tableWrapperRef.current) {
    tableRef.current = tableWrapperRef.current.querySelector(".ant-table-body");
  }

  const weekDaysData = useMemo(
    () =>
      weekDates.map((weekdayEntry: any) => {
        const cartId = weekCarts
          ? weekCarts.find(
              (entry: any) => entry.deliveryDate === weekdayEntry.apiDate
            )?.id
          : null;

        return {
          ...weekdayEntry,
          cartId,
        };
      }),
    [weekDates, weekCarts]
  );

  const isPromotedCategory = useMemo(() => {
    const { category } = routePathNames;
    const { christmasMeatPromotion, easterMeatPromotion, easterPromotion } =
      routeProductCategoryKeys;

    return (
      pathname.includes(`${category}${christmasMeatPromotion}`) ||
      pathname.includes(`${category}${easterMeatPromotion}`) ||
      pathname.includes(`${category}${easterPromotion}`)
    );
  }, [pathname]);

  /**
   * set current active week by creating a new date set
   * @param {string} direction
   */
  const setWeek = useCallback(
    (direction: string) => {
      const { apiDate: currentDay, apiDate: currentCalenderWeek } =
        weekDaysData[0];

      let nextWeekStartDate;

      // create new data only if the step seems legit, otherwise opt out
      if (
        direction === "next" &&
        moment(currentCalenderWeek).add(1, "week").format(apiDateFormat) <=
          maxWeek
      ) {
        nextWeekStartDate = moment(currentDay).add(7, "days");
      } else if (
        direction === "back" &&
        moment(currentCalenderWeek).subtract(1, "week").format(apiDateFormat) >=
          minWeek
      ) {
        nextWeekStartDate = moment(currentDay).subtract(7, "days");
      } else {
        return null;
      }

      // update redux weekdays
      const newWeekData = updateWeekplannerDates(nextWeekStartDate);

      const { search: paramSearch } = queryString.parse(search);
      const searchTerm = paramSearch?.length ? paramSearch.toString() : null;

      if (newWeekData) {
        setUpdateUrlFragments({
          context: "weekPlanner",
          parameters: {
            searchTerm,
            from: newWeekData[0]?.apiDate,
            to: newWeekData[newWeekData.length - 1]?.apiDate,
          },
        });
      }

      return true;
    },
    [maxWeek, minWeek, setUpdateUrlFragments, weekDaysData, search]
  );

  // Only use day that are selected in daysToShow
  const filteredDaysToShow = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(daysToShow).filter(([, value]) => value === true)
      ),
    [daysToShow]
  );
  // use the filtered list above to build an array of table-cell objects
  // with this solution there is no need to type 6 nearly identical objects any more
  const filteredDaysToShowData = useMemo(
    () =>
      weekDaysData
        .map((currentWeekdayData: any, index: number) => {
          if (filteredDaysToShow[currentWeekdayData.day]) {
            const { dateString, apiDate, cartId, isPastDate, isToday } =
              currentWeekdayData;

            return {
              title: (
                <TableTitle
                  deliveryDate={apiDate}
                  weekdayData={currentWeekdayData}
                />
              ),
              dataIndex: `Day${index}`,
              width: useSimpleMode ? 125 : 140,
              className: clsx(
                "weekoverview-table--border weekoverview-table--cell column-title",
                isToday && "weekoverview-table--today",
                isPastDate && "weekoverview-table--past-date"
              ),
              render: (data: any = {}) => {
                const { display, tabIndex, productItem } = data;

                if (!productItem) {
                  return null;
                }

                const { sku, prices } = productItem;

                if (!sku || !prices) {
                  return null;
                }

                // check if the product is part of an already ordered cart
                const pastOrderedProduct =
                  alreadyOrderedItemsForDate?.[sku]?.[apiDate];
                if (sku && isPastDate && pastOrderedProduct) {
                  return (
                    <div>
                      <span>Bestellt</span>
                      <ProductAlreadyOrderedBatch count={pastOrderedProduct} />
                    </div>
                  );
                }

                if (sku && !isPastDate) {
                  return (
                    <WeekPlannerProductCell
                      cartId={cartId}
                      date={dateString}
                      deliveryDate={apiDate}
                      display={display}
                      isLoading={isLoading}
                      tabIndex={tabIndex}
                      pastOrderedProduct={pastOrderedProduct}
                      productData={productItem}
                      getContainer={() =>
                        (isInFullscreenMode && tableWrapperRef?.current) ||
                        document.body
                      }
                      showCurrentVolumePrice={!useSimpleMode}
                    />
                  );
                }

                return null;
              },
            };
          }

          return null;
        })
        .filter((dayData: any) => dayData),
    [
      isLoading,
      alreadyOrderedItemsForDate,
      filteredDaysToShow,
      weekDaysData,
      isInFullscreenMode,
      useSimpleMode,
    ]
  );

  const defaultModeCols = useMemo(
    () => [
      {
        title: isInFullscreenMode ? (
          <TableWeekPagination
            minWeek={minWeek}
            maxWeek={maxWeek}
            weekDaysData={weekDaysData}
            setWeek={setWeek}
            setHasRequestedFullScreen={setHasRequestedFullScreen}
            isInFullscreenMode={isInFullscreenMode}
          />
        ) : (
          ""
        ),
        dataIndex: "articleElement",
        width: 550,
        fixed: true,
        ellipsis: true,
        className: clsx(
          "weekoverview-table--col weekoverview-table--cell column-title",
          isInFullscreenMode && "column-title--primary"
        ),
        render: (itemRowData: any = {}) => {
          const { icons, img, productItem } = itemRowData;

          // assume, that if there is no productItem, we want to render the given data, e.g. a grouping;
          if (!productItem) {
            return itemRowData;
          }

          const { name, sku } = productItem;

          if (!sku || !name) {
            return null;
          }

          return (
            <WeekPlannerProductInformationCell
              img={img}
              icons={icons}
              productData={productItem}
              weekDaysData={weekDaysData}
              getContainer={() =>
                (isInFullscreenMode && tableWrapperRef?.current) ||
                document.body
              }
            />
          );
        },
      },
      {
        title: (
          <span className="text-bold">
            Preis-
            <br />
            informationen
          </span>
        ),
        width: 130,
        className: "weekoverview-table--cell column-title",
        dataIndex: "articleElement",
        render: (data: any = {}) => {
          const { productItem } = data;

          if (!productItem) {
            return <></>;
          }

          return (
            <WeekPlannerPriceInformationCell
              productData={productItem}
              getContainer={() =>
                (isInFullscreenMode && tableWrapperRef?.current) ||
                document.body
              }
            />
          );
        },
      },
      ...filteredDaysToShowData,
    ],
    [
      weekDaysData,
      isInFullscreenMode,
      maxWeek,
      minWeek,
      setWeek,
      filteredDaysToShowData,
    ]
  );

  const simpleModeCols = useMemo(() => {
    return [
      {
        title: "Art.-Nr.",
        width: 70,
        align: "center",
        fixed: true,
        dataIndex: "articleElement",
        render: (itemRowData: any = {}) => (
          <WeekPlannerSkuCell
            itemRowData={itemRowData}
            getContainer={() =>
              (isInFullscreenMode && tableWrapperRef?.current) || document.body
            }
          />
        ),
      },
      {
        title: "Bez., Hersteller",
        width: 215,
        dataIndex: "articleElement",
        render: (itemRowData: any = {}) => (
          <WeekPlannerSimpleProductInformationCell
            itemRowData={itemRowData}
            weekDaysData={weekDaysData}
            getContainer={() =>
              (isInFullscreenMode && tableWrapperRef?.current) || document.body
            }
          />
        ),
      },
      ...filteredDaysToShowData,
      {
        title: "Ursprung",
        align: "center",
        width: 60,
        dataIndex: "articleElement",
        render: (itemRowData: any = {}) => (
          <WeekPlannerAttributeCell
            itemRowData={itemRowData}
            attributeName="origin"
          />
        ),
      },
      {
        title: "Qualität",
        align: "center",
        width: 50,
        dataIndex: "articleElement",
        render: (itemRowData: any = {}) => (
          <WeekPlannerAttributeCell
            itemRowData={itemRowData}
            attributeName="quality"
          />
        ),
      },
      {
        title: "VPE",
        align: "center",
        width: 50,
        dataIndex: "articleElement",
        render: (itemRowData: any = {}) => {
          const { productItem } = itemRowData;

          if (!productItem) {
            return null;
          }

          const { attributes } = productItem || {};

          const {
            [productAttributes.unitQuantity]: unitQuantity,
            [productAttributes.package]: productUnitMeasurement,
          } = attributes;

          return <div>{`${unitQuantity} x ${productUnitMeasurement}`}</div>;
        },
      },
      {
        title: "1 Staffel",
        align: "center",
        width: 50,
        dataIndex: "articleElement",
        render: (itemRowData: any = {}) => (
          <WeekPlannerVolumePriceCell itemRowData={itemRowData} quantity={1} />
        ),
      },
      {
        title: "2 Staffel",
        align: "center",
        width: 50,
        dataIndex: "articleElement",
        render: (itemRowData: any = {}) => (
          <WeekPlannerVolumePriceCell itemRowData={itemRowData} quantity={2} />
        ),
      },
      {
        title: "5 Staffel",
        align: "center",
        width: 50,
        dataIndex: "articleElement",
        render: (itemRowData: any = {}) => (
          <WeekPlannerVolumePriceCell itemRowData={itemRowData} quantity={5} />
        ),
      },
      {
        title: "10 Staffel",
        align: "center",
        width: 60,
        dataIndex: "articleElement",
        render: (itemRowData: any = {}) => (
          <WeekPlannerVolumePriceCell itemRowData={itemRowData} quantity={10} />
        ),
      },
      {
        title: "Rollwagen",
        align: "center",
        width: 60,
        dataIndex: "articleElement",
        render: (itemRowData: any = {}) => (
          <WeekPlannerVolumePriceCell
            itemRowData={itemRowData}
            priceType="trolley"
          />
        ),
      },
      {
        title: "Palette",
        align: "center",
        width: 50,
        dataIndex: "articleElement",
        render: (itemRowData: any = {}) => (
          <WeekPlannerVolumePriceCell
            itemRowData={itemRowData}
            priceType="pallet"
          />
        ),
      },
      {
        title: "",
        align: "center",
        width: 60,
        dataIndex: "articleElement",
        render: (itemRowData: any = {}) => {
          const { productItem } = itemRowData;

          if (!productItem) {
            return null;
          }

          const { sku } = productItem || {};

          return (
            <Space>
              <ProductAddToPriceTagListButton sku={sku} />

              <ProductAddToFavouritesListButton
                sku={sku}
                getContainer={() =>
                  (isInFullscreenMode && tableWrapperRef?.current) ||
                  document.body
                }
              />
            </Space>
          );
        },
      },
    ];
  }, [filteredDaysToShowData, weekDaysData, isInFullscreenMode]);

  const cols = useMemo(
    () => [...(useSimpleMode ? simpleModeCols : defaultModeCols)],
    [useSimpleMode, simpleModeCols, defaultModeCols]
  );

  /*
   * create product row for table, creates object with row based data for each cell in a row
   * split the items in groups of product groups, memoize them to reduce costs
   */
  const weekplannerItemRows = useMemo(
    () =>
      productData.reduce(
        (accumulator: ProductData[], currentProductEntry: ProductData) => {
          const { sku, imageSets, attributes } = currentProductEntry;

          let currentProductGroupList: Record<string, any>;
          const dayBasedData: Record<string, any> = {};

          // find product group from attributes
          const currentProductGroup =
            attributes[productAttributes.weekplannerGroupHeadline];

          const matchingProductGroup = accumulator.find(
            (weekplannerItemGroup: any) =>
              weekplannerItemGroup.productGroup === currentProductGroup
          );

          // ALL other productGroups that are NOT currentProductGroup
          const notMatchingProductGroup = accumulator.filter(
            (cartProductGroup: any) =>
              cartProductGroup.productGroup !== currentProductGroup
          );

          // if no matching group by product group is available
          currentProductGroupList = compareProductCollectionGroups({
            productGroup: matchingProductGroup,
            lookupGroup: currentProductGroup,
            groupType: "productGroup",
          });

          /**
           * data for 6 days (Mo - Sa)
           * contains the set of data which gets passed to the
           * render function for filteredDaysToShowData
           *
           * TabIndex: build a string that like 13
           * 1 : start (1)
           * i: number of day (3)
           *
           */
          for (let i = 0; i < 6; i += 1) {
            dayBasedData[`Day${i}`] = {
              display:
                daysToShow[
                  moment(weekDaysData[i].dateString, dateFormat).format("dddd")
                ],
              tabIndex: `1${i}`,
              productItem: currentProductEntry,
            };
          }

          // fill with current item
          currentProductGroupList = {
            ...currentProductGroupList,
            children: [
              ...currentProductGroupList.children,
              {
                key: sku,
                articleElement: {
                  productItem: currentProductEntry,
                  img: imageSets?.length
                    ? imageSets[0].images[0].externalUrlSmall
                    : "",
                  icons: attributes[productAttributes.quality]
                    ? attributes[productAttributes.quality].split(" ")
                    : [],
                },
                ...dayBasedData,
              },
            ],
          };

          return [...notMatchingProductGroup, currentProductGroupList];
        },
        []
      ),
    [productData, daysToShow, weekDaysData]
  );

  // memoize the parent rows
  const expandableRowKeys = useMemo(
    () => weekplannerItemRows.map((parentRow: any) => parentRow.key),
    [weekplannerItemRows]
  );

  /**
   * calculate and shift table if mouse is moved and dragging active
   * @param event
   */
  const onMouseMove = (event: React.MouseEvent) => {
    if (isDragging && tableRef.current) {
      // calculate delta of movements
      const deltaX = event.clientX - cursorPosition.x;
      const deltaY = event.clientY - cursorPosition.y;

      // update position of table
      tableRef.current.scrollTop = cursorPosition.top - deltaY;
      tableRef.current.scrollLeft = cursorPosition.left - deltaX;
    }
  };

  /**
   * mouse down handler
   * sets the current position to state for calculations
   * sets drag state to update styles
   * @param event
   */
  const onMouseDown = (event: React.MouseEvent) => {
    if (tableRef.current) {
      const position = {
        left: tableRef.current.scrollLeft,
        top: tableRef.current.scrollTop,
        x: event.clientX,
        y: event.clientY,
      };

      setCursorPosition(position);
      setIsDragging(true);
    }
  };

  /**
   * update dragging state if dragging ends
   */
  const onMouseUp = () => {
    setIsDragging(false);
  };

  // Read params from URL and update week planner
  useEffect(() => {
    const { deliveryDateFrom } = queryString.parse(search);
    updateWeekplannerDates(moment(deliveryDateFrom));
  }, [search]);

  // Put table into fullscreen mode
  useEffect(() => {
    const currentTableWrapperRef = fullscreenRef;

    if (
      currentTableWrapperRef &&
      hasRequestedFullScreen &&
      !isInFullscreenMode
    ) {
      currentTableWrapperRef
        .requestFullscreen()
        .then(() => {
          setIsInFullscreenMode(true);
          setHasRequestedFullScreen(false);
        })
        .catch(() => {
          setIsInFullscreenMode(false);
          setHasRequestedFullScreen(false);
        });
    }
  }, [fullscreenRef, hasRequestedFullScreen, isInFullscreenMode]);

  // Handle fullscreen change event
  useEffect(() => {
    const currentTableWrapperRef = fullscreenRef;

    const fullscreenHandler = () => {
      if (document.fullscreenElement?.nodeName) {
        setIsInFullscreenMode(true);
      } else {
        setIsInFullscreenMode(false);
      }
    };

    if (currentTableWrapperRef) {
      currentTableWrapperRef.addEventListener(
        "fullscreenchange",
        fullscreenHandler
      );
    }

    return () => {
      if (currentTableWrapperRef) {
        currentTableWrapperRef.removeEventListener(
          "fullscreenchange",
          fullscreenHandler
        );
      }
    };
  }, [fullscreenRef]);

  // For promoted categories change default sort
  useEffect(() => {
    const { sortBy } = queryString.parse(search);

    if (!sortBy && isPromotedCategory) {
      setUpdateUrlFragments({
        context: "sort",
        parameters: { sortBy: "warengruppe", sortDirection: "asc" },
      });
    }
  }, [search, isPromotedCategory, setUpdateUrlFragments]);

  if (!weekplannerIsVisible) {
    return (
      <Result
        className="weekPlannerMobileErrorMessage"
        title="Der Wochenplaner ist nur für größere Browserbreiten verfügbar. Nutzen Sie ein Tablet und halten Sie das Tablet im Quer-Format (Landscape)."
      />
    );
  }

  return (
    <>
      <TrackingHelmet title={pageTitles.weekPlanner} suffix={pageTitleSuffix} />

      <Row className={clsx(!productData?.length && "mb-l")}>
        <Col xs={12} lg={12}>
          <FilterBar
            showFilters={showFilters}
            setShowFilters={setShowFilters}
            showSearchFilter
            selectedFiltersCount={selectedFiltersCount}
            view="weekplanner"
          />

          <FiltersDesktop
            showFilters={showFilters}
            hideFilters={() => setShowFilters(false)}
            loading={isLoading}
            display="weekplanner"
          />
          {!isInFullscreenMode && (
            <Col xs={0} md={12} style={{ paddingLeft: 0, paddingRight: 0 }}>
              <SelectedFilters />
            </Col>
          )}
        </Col>
      </Row>
      {pagination}
      <Row>
        <Col xs={12} className="weekplanner-main">
          <Layout className="container-layout">
            {!isInFullscreenMode && (
              <TableWeekPagination
                minWeek={minWeek}
                maxWeek={maxWeek}
                weekDaysData={weekDaysData}
                setWeek={setWeek}
                setHasRequestedFullScreen={setHasRequestedFullScreen}
                isInFullscreenMode={isInFullscreenMode}
                useSimpleMode={useSimpleMode}
              />
            )}

            <div
              className={clsx(
                "tableWrapper",
                weekplannerItemRows?.length > 0 && "isDraggable",
                isDragging && "isDragging"
              )}
              role="presentation"
              onMouseMove={onMouseMove}
              onMouseDown={onMouseDown}
              onMouseUp={onMouseUp}
              ref={tableWrapperRef}
            >
              <Table
                components={null}
                columns={weekplannerItemRows?.length > 0 && cols}
                dataSource={weekplannerItemRows}
                pagination={false}
                scroll={{
                  x: "100%",
                }}
                className={clsx(
                  "weekplannerTable",
                  isInFullscreenMode && "weekplannerTable--fullscreen",
                  useSimpleMode && "weekplannerTable--simple"
                )}
                sticky={{ offsetHeader: isInFullscreenMode ? 25 : 120 }}
                loading={isLoading && { size: "large" }}
                locale={locale}
                expandable={{
                  columnWidth: "100%",
                  expandedRowKeys: expandableRowKeys,
                  // hide default expand icon
                  expandIconColumnIndex: -1,
                  expandRowByClick: true,
                }}
              />
            </div>
          </Layout>
        </Col>
      </Row>

      <ButtonBackToTop />
    </>
  );
};

export default WeekPlanner;
