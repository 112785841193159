import React from "react";

import clsx from "clsx";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useMatomo } from "@jonkoops/matomo-tracker-react";

import { routePathNames } from "../../../appConfig";
import { ReactComponent as Arrow } from "../../../static/svg/arrow.svg";
import { RootState } from "../../../types/rootState";

interface TableWeekPaginationProps {
  minWeek: number;
  maxWeek: number;
  weekDaysData: any[];
  setWeek: (param: string) => void;
  setHasRequestedFullScreen: (param: boolean) => void;
  isInFullscreenMode?: boolean;
  useSimpleMode?: boolean;
  className?: string;
}

const TableWeekPagination: React.FC<TableWeekPaginationProps> = (
  props: TableWeekPaginationProps
) => {
  const {
    minWeek,
    maxWeek,
    weekDaysData,
    setWeek,
    setHasRequestedFullScreen,
    isInFullscreenMode = false,
    useSimpleMode = false,
    className,
  } = props;

  const { calenderWeek, apiDate: currentDate } = weekDaysData?.[0] || {};

  const { id } = useSelector((state: RootState) => state?.userData || {});

  const dispatch = useDispatch();

  const { trackEvent } = useMatomo();

  return (
    <div className={clsx("weekplannerTablePagination", className)}>
      {!isInFullscreenMode && (
        <div className="buttonFullscreen">
          <button
            onClick={() => setHasRequestedFullScreen(true)}
            type="button"
            className="buttonText buttonText--inverted"
          >
            Vollbildmodus
          </button>
          <span>&nbsp;|&nbsp;</span>
          <button
            onClick={() => {
              if (!useSimpleMode) {
                trackEvent({
                  category: "button",
                  action: "track activity of classic weekplanner view",
                  name: `Klassische Ansicht`,
                });
              }
              dispatch({
                type: "user/update-user",
                payload: {
                  weekplannerUseSimpleMode: !useSimpleMode,
                },
              });
            }}
            type="button"
            className="buttonText buttonText--inverted"
          >
            {`${useSimpleMode ? "Standard" : "Klassische"} Ansicht`}
          </button>
        </div>
      )}

      <button
        type="button"
        className="weekplannerPaginationButton"
        disabled={currentDate === minWeek}
        onClick={() => setWeek("back")}
      >
        <Arrow className="pagination-arrow-left" />
      </button>

      <span>
        <span className="pagination-week">KW {calenderWeek}</span>{" "}
        {weekDaysData[0].dateString.split(", ")[1]} -{" "}
        {weekDaysData[5].dateString.split(", ")[1]}
      </span>

      <button
        type="button"
        className="weekplannerPaginationButton"
        onClick={() => setWeek("next")}
        disabled={currentDate === maxWeek}
      >
        <Arrow className="pagination-arrow-right" />
      </button>

      {!isInFullscreenMode && (
        <Link
          to={`${routePathNames.userProfileEdit}${id}`}
          className="edit-table-info"
        >
          Liefertage ändern
        </Link>
      )}
    </div>
  );
};

export default TableWeekPagination;
