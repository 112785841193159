import React from "react";
import ProductAlternativesModal from "../ProductModal/ProductAlternativesModal";
import ProductTileInner from "./ProductTileInner";
import getDeliveryDateBasedAttributes from "../getDeliveryDateBasedAttributes";
import ProductTileWrapper from "./ProductTileWrapper";
import AddToCartDispatch from "./ProductTileAddToCartContext";
import { ProductTileProps } from "../../../types/productTileProps";

/**
 * Product Tile with an alternatives Modal
 * has to be a seperated component to prevent import loops
 * @param deliveryDate {string}
 * @param productData {ProductData}
 * @param addToCartCallback {Function}
 * @constructor
 */
const ProductTileWithModal: React.FC<ProductTileProps> =
  function ProductTileWithModal({
    deliveryDate,
    productData,
    addToCartCallback = () => true,
    setFocusOnSearchInput,
  }: ProductTileProps) {
    const { sku, availabilities } = productData;
    const { isUnavailable } = getDeliveryDateBasedAttributes({
      deliveryDate,
      availabilities,
    });

    return (
      <AddToCartDispatch.Provider value={addToCartCallback}>
        <ProductTileWrapper
          deliveryDate={deliveryDate}
          productData={productData}
        >
          <ProductTileInner
            deliveryDate={deliveryDate}
            productData={productData}
            setFocusOnSearchInput={setFocusOnSearchInput}
          />

          {isUnavailable && (
            <ProductAlternativesModal deliveryDate={deliveryDate} sku={sku} />
          )}
        </ProductTileWrapper>
      </AddToCartDispatch.Provider>
    );
  };

export default ProductTileWithModal;
