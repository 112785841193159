import React from "react";

import clsx from "clsx";
import { useSelector } from "react-redux";

import Price from "../../price/Price";
import { productAttributes } from "../../../api/productAttributes";
import { RootState } from "../../../types/rootState";

interface WeekPlannerVolumePriceCellProps {
  itemRowData: any;
  quantity?: number;
  priceType?: "default" | "trolley" | "pallet";
  className?: string;
}

const WeekPlannerVolumePriceCell: React.FC<WeekPlannerVolumePriceCellProps> = (
  props: WeekPlannerVolumePriceCellProps
) => {
  const { itemRowData, quantity = 1, priceType = "default", className } = props;

  const hidePrices = useSelector(
    (state: RootState) => state?.appSettings?.hidePrices || false
  );

  const { productItem } = itemRowData;

  if (!productItem) {
    return null;
  }

  const { attributes } = productItem;

  const { [productAttributes.unitQuantity]: productUnitQuantity } = attributes;

  const { prices } = productItem || {};

  const volumePrices = prices?.[0]?.volumePrices || [];

  let price;

  if (priceType === "default") {
    price = volumePrices?.find((vp: any) => vp?.quantity === quantity)?.price;
  } else {
    const otherPriceTypes = volumePrices.filter((vp: any) => vp?.quantity > 10);
    price =
      priceType === "trolley"
        ? otherPriceTypes?.[0]?.price
        : otherPriceTypes?.[1]?.price;
  }

  if (!price || !Number.isFinite(price)) {
    return null;
  }

  return (
    <div className={clsx(hidePrices && "blurred", className)}>
      <Price price={price / parseFloat(productUnitQuantity)} />
    </div>
  );
};

export default WeekPlannerVolumePriceCell;
