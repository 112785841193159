import React, { useMemo } from "react";

import clsx from "clsx";

import getDeliveryDateBasedAttributes from "../../product/getDeliveryDateBasedAttributes";
import useStockForAttribute from "../../../hooks/useStockForAttribute";
import ProductInfoVolumePricesPopup from "../../product/ProductInfoVolumePricesPopup";
import ProductPrices from "../../product/ProductPrices";
import { productAttributes } from "../../../api/productAttributes";
import { ProductData } from "../../../types/productData";

interface WeekPlannerPriceInformationCellProps {
  productData: ProductData;
  className?: string;
  getContainer?: () => HTMLElement;
}

const WeekPlannerPriceInformationCell: React.FC<
  WeekPlannerPriceInformationCellProps
> = (props) => {
  const { productData = {} as ProductData, className, getContainer } = props;
  const { attributes, prices } = productData;
  const deliveryDate = prices?.[0]?.deliveryDate;

  const {
    [productAttributes.unitQuantity]: productUnitQuantity,
    [productAttributes.package]: productUnitMeasurement,
    [productAttributes.basePriceFactor]: basePriceFactor,
    [productAttributes.basePriceUnit]: basePriceUnit,
    [productAttributes.weighingArticle]: weighingArticle,
  } = attributes;

  const advertisement = useStockForAttribute(attributes, "advert");
  const { volumePrices, strikePrice } = useMemo(
    () =>
      getDeliveryDateBasedAttributes({
        deliveryDate,
        prices,
      }),
    [deliveryDate, prices]
  );

  return (
    <div className={clsx("weekplanner-price-information-cell", className)}>
      <div className="weekplanner-product-price">
        <ProductPrices
          prices={prices}
          deliveryDate={deliveryDate}
          quantity={1}
          advertisement={advertisement}
          basePriceQuantity={productUnitQuantity}
          basePriceUnit={basePriceUnit}
          basePriceFactor={basePriceFactor}
          productUnitMeasurement={productUnitMeasurement}
          weighingArticle={weighingArticle}
          sku={productData?.sku}
          showProductUVP
        />
      </div>

      {volumePrices?.length > 0 && (
        <ProductInfoVolumePricesPopup
          volumePrices={volumePrices}
          basePriceQuantity={weighingArticle === "1" ? 1 : productUnitQuantity}
          className={clsx(strikePrice > 0 && "has-strike-through-price")}
          view="weekplanner"
          getContainer={getContainer}
        />
      )}
    </div>
  );
};

export default WeekPlannerPriceInformationCell;
