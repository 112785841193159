import React from "react";

import clsx from "clsx";
import { Skeleton, Space } from "antd";

import QuickOrderProductTile from "../QuickOrderProductTile";
import QuickOrderProductPrices from "../QuickOrderProductPrices";
import { ProductData } from "../../../../types/productData";

interface QuickOrderItemProps {
  deliveryDate: string;
  product: ProductData;
  isSearching: boolean;
  className?: string;
}

const QuickOrderItem: React.FC<QuickOrderItemProps> = (
  props: QuickOrderItemProps
) => {
  const { deliveryDate, product = null, isSearching, className } = props;

  return (
    <div className={clsx("quick-order-item", className)}>
      {isSearching && (
        <Space align="start" className="quick-order-item__skeleton">
          <Skeleton.Image active={isSearching} />
          <Space direction="vertical">
            <Skeleton.Input active={isSearching} />
            <Skeleton.Input active={isSearching} />
          </Space>
        </Space>
      )}

      {!!product && !isSearching && (
        <QuickOrderProductTile
          deliveryDate={deliveryDate}
          productData={product}
        />
      )}

      {!!product && !isSearching && (
        <QuickOrderProductPrices
          deliveryDate={deliveryDate}
          productData={product}
        />
      )}
    </div>
  );
};

export default QuickOrderItem;
