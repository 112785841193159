import React, { useEffect, useState } from "react";
import "moment/locale/de";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { WeekdayData } from "../../../utils/createWeekplannerDates";
import ButtonAddToCart from "../../buttons/ButtonAddToCart";
import LinkToCart from "../../../Router/LinkToCart";
import getCartItemsTotalValue from "../../../utils/getCartItemsTotalValue";
import { parsePriceValueToLocalPrice } from "../../../utils/localizedNumberFormat";

interface Props {
  deliveryDate: string;
  weekdayData: WeekdayData;
}

const TableTitle: React.FC<Props> = ({ deliveryDate, weekdayData }: Props) => {
  const { dateString, apiDate, isPastDate, isToday } = weekdayData;
  const cartItemsTotalValue = useSelector((state: any) =>
    getCartItemsTotalValue(
      state.weekplannerData.weekCarts.find(
        (entry: any) => entry.deliveryDate === apiDate
      )?.deliveryDateItems || []
    )
  );

  const [itemsInPreviousCart, setItemsInPreviousCart] =
    useState(cartItemsTotalValue);
  const [shakeCart, setShakeCart] = useState(false);

  useEffect(() => {
    let shakeTimer: number;

    if (cartItemsTotalValue !== itemsInPreviousCart) {
      setShakeCart(true);

      shakeTimer = window.setTimeout(() => {
        setShakeCart(false);
      }, 1000);

      setItemsInPreviousCart(cartItemsTotalValue);
    }

    return () => {
      if (shakeTimer) {
        window.clearTimeout(shakeTimer);
      }
    };
  }, [cartItemsTotalValue, itemsInPreviousCart]);

  const splitDateString = dateString.split(",");
  const monthDayString = apiDate.split("-")?.[2] ?? "";
  const monthNumberString = apiDate.split("-")?.[1] ?? "";

  return (
    <div className="weekplannerTableHeading">
      <span>
        {isToday && <i>Heute&nbsp;</i>}
        <b>{splitDateString?.[0] ?? ""}</b>
        <br /> {`${monthDayString}.${monthNumberString}.`}
      </span>

      {!isPastDate && deliveryDate && (
        <LinkToCart deliveryDate={deliveryDate}>
          <ButtonAddToCart
            type="default"
            withBadge={cartItemsTotalValue > 0}
            badgeCount={parsePriceValueToLocalPrice(
              cartItemsTotalValue,
              "EUR",
              {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }
            )}
            title={`Zahlbetrag: ${parsePriceValueToLocalPrice(
              cartItemsTotalValue,
              "EUR",
              {
                maximumFractionDigits: 2,
              }
            )}`}
            className={clsx(shakeCart && "shake")}
          />
        </LinkToCart>
      )}
    </div>
  );
};

export default TableTitle;
