import { Drawer, Modal } from "antd";
import React, { useState } from "react";
import clsx from "clsx";
import { ProductData } from "../../../types/productData";
import { ReactComponent as PackageIcon } from "../../../static/svg/choices_package.svg";
import { ModalButton } from "../../../types/button";
import ProductTileWithoutModal from "../ProductTile/ProductTileWithoutModal";
import useMedia from "../../../hooks/useMedia";
import getCssVariable from "../../../utils/getCssVariable";
import { ReactComponent as Close } from "../../../static/svg/close.svg";

interface ProductViewerData {
  deliveryDate: ProductData["deliveryDate"];
  products: ProductData[];
  title: string;
  buttonTitle: string;
  buttonStyle?: ModalButton;
  buttonClass?: string;
  defaultQuantity?: number;
  getContainer?: () => HTMLElement;
}

/**
 * Product Viewer with button, almost fully customizable
 * @param {ProductData["deliveryDate"]} deliveryDate
 * @param {ProductData} products
 * @param {string} title
 * @param {string} buttonTitle
 * @param {ModalButton} buttonStyle
 * @param {string} buttonClass
 * @param {number} defaultQuantity
 * @param getContainer
 * @constructor
 */
const ProductViewer = function ProductViewer({
  deliveryDate,
  products,
  title,
  buttonTitle,
  buttonStyle = "primary",
  buttonClass,
  defaultQuantity,
  getContainer,
}: ProductViewerData) {
  const [isVisible, setIsVisible] = useState(false);

  const isLargeScreen = useMedia(`(min-width: ${getCssVariable("screen-md")})`);

  const showViewer = () => setIsVisible(true);
  const hideViewer = () => setIsVisible(false);

  // viewer title
  const viewerHeading = (
    <h3 className="modalHeading">
      <PackageIcon className="modalHeadingIcon" />
      {title}
    </h3>
  );

  return (
    <>
      <button
        type="button"
        className={clsx(
          buttonStyle === "primary" && "button buttonPrimary",
          buttonStyle === "text" && "buttonText",
          buttonClass
        )}
        onClick={showViewer}
      >
        {buttonTitle}
      </button>

      {isLargeScreen ? (
        /* DESKTOP */
        <Modal
          title={viewerHeading}
          visible={isVisible}
          footer={null}
          onCancel={hideViewer}
          destroyOnClose
          width={1000}
          className="alternative-product-modal"
          getContainer={getContainer}
        >
          {products.map((currentProduct: ProductData) => (
            <ProductTileWithoutModal
              key={currentProduct.sku}
              deliveryDate={deliveryDate}
              productData={currentProduct}
              defaultQuantity={defaultQuantity}
            />
          ))}
        </Modal>
      ) : (
        /* MOBILE | TABLET */
        <Drawer
          title={viewerHeading}
          placement="bottom"
          onClose={hideViewer}
          visible={isVisible}
          key="bottom"
          closable={false}
          className="productViewer"
          // not able to set the height with css classes, therefore it set as component style
          height={600}
          contentWrapperStyle={{ maxHeight: "90vh" }}
          extra={
            <button
              type="button"
              title="Schließen"
              className="button buttonPlain buttonWithIcon buttonWithIcon--withoutText"
              onClick={hideViewer}
            >
              <Close className="closeIcon closeIcon--large" />
            </button>
          }
        >
          {products.map((currentProduct: ProductData) => (
            <ProductTileWithoutModal
              key={currentProduct.sku}
              deliveryDate={deliveryDate}
              productData={currentProduct}
              defaultQuantity={defaultQuantity}
            />
          ))}
        </Drawer>
      )}
    </>
  );
};

export default ProductViewer;
