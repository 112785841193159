import moment from "moment";
import React from "react";
import { Space } from "antd";
import { ProductData } from "../../../types/productData";
import { ReactComponent as UnavailableIcon } from "../../../static/svg/shopping-cart-unavailable.svg";
import ProductAlternativesModal from "../../product/ProductModal/ProductAlternativesModal";
import ProductPreorderModal from "../../product/ProductModal/ProductPreorderModal";

interface UnavailableContentProps {
  deliveryDate: ProductData["deliveryDate"];
  hasProductAlternative: boolean;
  nextAvailability: string;
  productData: ProductData;
  getContainer?: () => HTMLElement;
}

/**
 * unavailable box for weekPlanner component
 * @param deliveryDate {string}
 * @param hasProductAlternative {boolean}
 * @param nextAvailability {string}
 * @param productData {ProductData}
 * @constructor
 */
const UnavailableContent = function UnavailableContent({
  deliveryDate,
  hasProductAlternative,
  nextAvailability,
  productData,
  getContainer,
}: UnavailableContentProps) {
  return (
    <div className="unavailableBox">
      <Space direction="vertical">
        <header className="unavailableHeading">
          <UnavailableIcon className="icon iconUnavailable" />
          Nicht verfügbar
        </header>

        {nextAvailability !== null &&
          moment(nextAvailability).isAfter(deliveryDate) && (
            <ProductPreorderModal
              deliveryDate={deliveryDate}
              productData={productData}
              buttonStyle="primary"
              getContainer={getContainer}
            />
          )}

        {hasProductAlternative && (
          <ProductAlternativesModal
            deliveryDate={deliveryDate}
            sku={productData?.sku}
            buttonStyle="primary"
            getContainer={getContainer}
          />
        )}
      </Space>
    </div>
  );
};

export default UnavailableContent;
