import React from "react";

import clsx from "clsx";

import {
  AttributeName,
  productAttributes,
} from "../../../api/productAttributes";

interface WeekPlannerAttributeCellProps {
  itemRowData: any;
  attributeName: AttributeName;
  className?: string;
}
const WeekPlannerAttributeCell: React.FC<WeekPlannerAttributeCellProps> = (
  props: WeekPlannerAttributeCellProps
) => {
  const { itemRowData, attributeName, className } = props;

  const { productItem } = itemRowData;

  if (!productItem) {
    return null;
  }

  const { attributes } = productItem || {};

  const { [productAttributes[attributeName]]: attribute } = attributes;

  return <div className={clsx(className)}>{attribute}</div>;
};

export default WeekPlannerAttributeCell;
