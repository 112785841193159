import React, { useRef, useState, useMemo, useEffect } from "react";
import { Skeleton } from "antd";
import moment from "moment";
import { useSelector } from "react-redux";
import { CancelTokenSource } from "axios";
import { ProductCount } from "../../product/index";
import useDebounceUpdateItemQuantity from "../../../hooks/useDebounceUpdateItemQuantity";
import { productAttributes } from "../../../api/productAttributes";
import { messageData } from "../../../appConfig";
import useCancelAxiosOnUnmount from "../../../hooks/useCancelAxiosOnUnmount";
import getCartErrorMessage from "../../../utils/getCartErrorMessage";
import getDeliveryDateBasedAttributes from "../../product/getDeliveryDateBasedAttributes";
import getCancelTokenSource from "../../../api/getCancelTokenSource";
import { ProductData } from "../../../types/productData";
import UnavailableContent from "./UnavailableContent";
import ProductPrices from "../../product/ProductPrices";
import ProductAlreadyOrderedBatch from "../../product/ProductAlreadyOrderedBatch";

interface Props {
  cartId: string;
  deliveryDate: any;
  display: boolean;
  isLoading: boolean;
  productData: ProductData;
  tabIndex: number;
  pastOrderedProduct?: any;
  getContainer?: () => HTMLElement;
  showCurrentVolumePrice?: boolean;
}

const WeekPlannerProductCell: React.FC<Props> = ({
  deliveryDate,
  cartId,
  display,
  tabIndex,
  isLoading,
  productData,
  pastOrderedProduct,
  getContainer,
  showCurrentVolumePrice = true,
}: Props) => {
  const { attributes, availabilities, sku, prices, productAlternatives } =
    productData;

  const {
    [productAttributes.unitQuantity]: productUnitQuantity,
    [productAttributes.package]: productUnitMeasurement,
    [productAttributes.basePriceUnit]: basePriceUnit,
    [productAttributes.weighingArticle]: weighingArticle,
  } = attributes;

  const { isUnavailable, nextAvailability, dailyProductAlternatives } = useMemo(
    () =>
      getDeliveryDateBasedAttributes({
        deliveryDate,
        prices,
        availabilities,
        productAlternatives,
      }),
    [deliveryDate, prices, availabilities, productAlternatives]
  );

  const { weekCarts } = useSelector((state: any) => state.weekplannerData);
  const currentCart = useMemo(
    () =>
      weekCarts
        ? weekCarts.find((cartEntry: any) => cartEntry.id === cartId)
        : null,
    [cartId, weekCarts]
  );

  // store token in reference to persist it over the lifecycles
  const cancelTokenSource = useRef<CancelTokenSource>(getCancelTokenSource());
  useCancelAxiosOnUnmount(cancelTokenSource.current);

  const [inputHasChanged, setInputHasChanged] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [selectedNumber, setSelectedNumber] = useState<number>(0);

  const initialNumber = useMemo(() => {
    return Number(
      currentCart?.deliveryDateItems?.find(
        (cartEntry: any) => cartEntry.sku === sku
      )?.quantity || 0
    );
  }, [currentCart, sku]);

  const isUnavailableForDate = useMemo(
    () =>
      isUnavailable &&
      !moment(nextAvailability).isSameOrBefore(moment(deliveryDate)),
    [deliveryDate, isUnavailable, nextAvailability]
  );

  const setItemQuantity = useDebounceUpdateItemQuantity({
    initialQuantity: initialNumber,
    deliveryDate,
    cartId,
    sku,
    setIsLoading: () => {
      setInputHasChanged(true);
    },
    resolve: (response) => {
      setInputHasChanged(false);
      if (response.config.method === "delete") {
        setAlertMessage(
          "Das Produkt wurde für diesen Tag aus dem Warenkorb entfernt."
        );
      } else {
        const cartErrorMessage = getCartErrorMessage({
          response,
          sku,
        });

        if (cartErrorMessage) {
          setAlertMessage(cartErrorMessage.reason);
          setSelectedNumber(Number(cartErrorMessage.quantityAdjusted));
        }
      }
    },
    reject: (error) => {
      setInputHasChanged(false);

      setAlertMessage(
        error?.response?.data?.errors[0]?.detail ||
          messageData.error.unexpected.content
      );
    },
    cancelTokenSource: cancelTokenSource.current,
  });

  // updates input, triggers debounced server update
  const handleChange = (value: number) => {
    setSelectedNumber(value);
    setItemQuantity(value);
  };

  /**
   * updated initial number if redux change happened
   */
  useEffect(() => {
    setSelectedNumber(initialNumber);
  }, [initialNumber]);

  return (
    <div className="weekplannerProductCell">
      <Skeleton active title={false} loading={isLoading} />

      {!isLoading && display && !!isUnavailableForDate && (
        <>
          <UnavailableContent
            deliveryDate={deliveryDate}
            hasProductAlternative={!!dailyProductAlternatives}
            nextAvailability={nextAvailability}
            productData={productData}
            getContainer={getContainer}
          />
          <ProductAlreadyOrderedBatch
            count={pastOrderedProduct}
            className="mb-s"
          />
        </>
      )}

      {!isLoading && display && !isUnavailableForDate && (
        <>
          <ProductCount
            value={selectedNumber}
            highlightThreshold={0}
            handleChange={handleChange}
            alertMessage={alertMessage}
            setAlertMessage={setAlertMessage}
            containerClassName="weekplannerCellProductCount"
            className="weekplannerCellProductCountInner"
            tabIndex={tabIndex}
            isLoading={inputHasChanged}
            pastOrderedProduct={pastOrderedProduct}
            getContainer={getContainer}
            useAddons
          />
          {!!selectedNumber && showCurrentVolumePrice && (
            <div className="weekplannerProductPrice">
              <ProductPrices
                prices={prices}
                deliveryDate={deliveryDate}
                quantity={Number(
                  selectedNumber + (pastOrderedProduct?.quantity || 0)
                )}
                basePriceQuantity={productUnitQuantity}
                basePriceUnit={basePriceUnit}
                productUnitMeasurement={productUnitMeasurement}
                weighingArticle={weighingArticle}
                sku={sku}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default WeekPlannerProductCell;
